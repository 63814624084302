import React from "react";
import classNames from "classnames";
import { Container, Text, AnimateIn } from "@atoms";

const Copy = ({ copy, showDivider, paddingTop }) => {
  return (
    <section
      className={classNames("pb-20 md:pb-28", {
        "pt-20 md:pt-28": paddingTop,
        "border-t-2 border-black": showDivider,
      })}
    >
      <AnimateIn preset="fadeUpFast" threshold="some">
        <Container>
          <Text variant="body" richText className="!max-w-[55ch]">
            {copy}
          </Text>
        </Container>
      </AnimateIn>
    </section>
  );
};

export default Copy;
